<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <img style="max-height: 150px;" :src="$root.$data.portalInfo.logoMain" />

        <div v-if="loggingIn">
          <p>Logging in</p>
        </div>

        <div v-if="$root.$data.loggedIn">
          <p>Already logged in</p>
        </div>

        <div v-if="!loggingIn && !$root.$data.loggedIn">
          <page-snippet name="login" />

          <div class="alert alert-danger" role="alert" v-if="errorMessage != ''">
            {{ errorMessage }}
          </div>

          <form>
            <table>
              <tr>
                <td>
                  <label for="username">
                    <template v-if="$root.$data.portalInfo.code == 'thso'">E-mail</template>
                    <template v-if="$root.$data.portalInfo.code != 'thso'">Username</template>
                  </label>
                </td>
                <td><b-form-input id="username" type="text" v-model="username" required autofocus /></td>
              </tr>
              <tr>
                <td><label for="password">Password</label></td>
                <td><b-form-input id="password" type="password" v-model="password" required autofocus /></td>
              </tr>
              <tr>
                <td></td>
                <td><b-button variant="primary" type="submit" @click.prevent="handleSubmit">Login</b-button></td>
              </tr>
            </table>
          </form>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>


  export default {
    name: "Login",
    components: {
      'page-snippet': () => import('@/components/PageSnippet')
    },
    data () {
      return {
        username: '',
        password: '',
        errorMessage: '',
        loggingIn: false,
        nextUrl: null,
      }
    },
    methods: {
      handleSubmit () {
        if (this.password.length > 0) {
          this.loggingIn = true

          this.$http.post('login', {
            username: this.username,
            password: this.password,
            providerId: this.$root.$data.portalInfo.providerId,
          })
          .then(response => {
            this.$root.afterLogin(response, this.nextUrl)
          })
          .catch((error) => {
            this.loggingIn = false
            if (!error.response) throw(error)

            if (error.response.status == 403) {
              this.errorMessage = 'Login failed, please check your e-mail and password.'
            } else {
              this.errorMessage = 'An unknown error has occurred, please reload the page and try again.'
            }
          });
        }
      }
    },
    mounted () {
      if (this.$route.params.errorMessage) {
        this.errorMessage = this.$route.params.errorMessage
      }

      if (this.$route.params.nextUrl) {
        this.nextUrl = this.$route.params.nextUrl
      }
    }
  };
</script>

<style scoped>
  label {
    font-weight: bold;
  }

  td:first-child {
    padding-right: 40px; 
  }


</style>